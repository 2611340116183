var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_vm.userRole != 'Client' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("organization")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.user_accesses && _vm.user_accesses.organization ? _c('span', [_vm._v(" " + _vm._s(_vm.user_accesses.organization.name) + " "), !_vm.user_accesses.organization.name ? _c('span', [_vm._v(" - ")]) : _vm._e()]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("email")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.currentUser.email ? _vm.currentUser.email : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("gender")))]), _vm.currentUser.gender == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("male")) + " ")]) : _vm._e(), _vm.currentUser.gender == 2 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("female")) + " ")]) : _vm._e(), !_vm.currentUser.gender ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("-")]) : _vm._e()]), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("staff-no")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.currentUser.staff_number ? _vm.currentUser.staff_number : "-") + " ")])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.currentUser.ic_number ? _vm.currentUser.ic_number : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.currentUser.mobile_number ? _vm.currentUser.mobile_number : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("office-no")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.currentUser.office_phone_number ? _vm.currentUser.office_phone_number : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("address")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.fullAddress ? _vm.fullAddress : "-") + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }